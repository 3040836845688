<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 245px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <div
                :class="type == 'failed' ? 'error--text' : 'success--text'"
                class="text-uppercase fs-14"
              >
                {{ type }}
              </div>
              <div>
                <input-qr-scan-model
                  v-model="code"
                  ref="codeRef"
                  :label="label"
                  @keyupEnter="scanInput"
                ></input-qr-scan-model>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(basket, bk) in baskets" :key="`b_${bk}_${basket.id}`">
            <td>
              <div
                class="font-weight-medium"
                :class="{
                  'primary--text text-decoration-underline':
                    activeBasket == basket.code,
                }"
              >
                {{ $t("labels.basket") }}:
                {{ basket.code }}
              </div>
              <div>
                <div
                  v-for="(item, ik) in basket.items"
                  :key="`i_${ik}_${item.id}`"
                >
                  {{ item.sku_uid }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div>
      <v-btn
        small
        block
        :color="type == 'failed' ? 'error' : 'success'"
        @click="addBasket"
        :disabled="step == 1"
      >
        {{ $t("labels.add_basket") }}
      </v-btn>
    </div>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.confirm')"
        :description="$t('labels.scan_basket_code_to_confirm')"
        :label="$t('labels.basket_code')"
        :placeholder="$t('labels.basket_code')"
        :codes="confirmCodes"
        @cancel="cancelConfirm"
        @confirm="onConfirmFailed"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "QCProcess",
  components: {
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
  },
  props: {
    idCommand: {
      type: [String, Number],
      default: () => null,
    },
    type: {
      type: String,
      default: () => "Passed",
    },
  },
  data: () => ({
    code: null,
    step: 1,
    isLoading: false,
    baskets: [],
    activeBasket: null,
    dialogConfirm: false,
  }),
  computed: {
    label() {
      if (this.step === 1) {
        return this.$t("labels.basket_code");
      }

      return this.$t("labels.uid");
    },

    confirmCodes() {
      return [this.activeBasket, `${this.activeBasket}`];
    },
  },
  mounted() {
    this.getProcessing();
  },
  methods: {
    formatDateTime,
    addBasket() {
      this.step = 1;
      this.code = null;
      this.$refs.codeRef.focusInput();
    },
    scanInput() {
      if (!this.code) {
        return false;
      }

      if (this.step === 1) {
        return this.getBasket();
      }

      if (this.type == "failed") {
        return this.getUid();
      } else {
        return this.scanUid();
      }
    },
    onConfirmFailed() {
      this.dialogConfirm = false;
      return this.scanUid();
    },
    async getProcessing() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/get-processing",
          {
            id: this.idCommand,
            type: this.type,
          }
        );

        if (data && data.length > 0) {
          this.step = 2;
          this.activeBasket = data[data.length - 1].code;
          this.baskets = [...data];
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getBasket() {
      const checkScanned = [...this.baskets].find(
        (basket) => basket.code == this.code
      );
      if (checkScanned) {
        this.activeBasket = checkScanned.code;
        this.code = null;
        this.step = 2;
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/get-basket-process",
          {
            id: this.idCommand,
            type: this.type,
            basket_code: this.code,
          }
        );

        const checkBasket = [...this.baskets].find(
          (basket) => basket.id == data.id
        );

        if (!checkBasket) {
          this.baskets.push({
            ...data,
            items: [],
          });
        }

        this.activeBasket = data.code;
        this.step = 2;
        this.isLoading = false;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async getUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/quality-control/v1/get-and-save-item", {
          code: this.code,
          id: this.idCommand,
          type: this.type,
          basket_code: this.activeBasket,
          get_only: true,
        });

        this.isLoading = false;
        this.dialogConfirm = true;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async scanUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/get-and-save-item",
          {
            code: this.code,
            id: this.idCommand,
            type: this.type,
            basket_code: this.activeBasket,
          }
        );

        this.isLoading = false;
        this.code = null;

        /* if (this.type == "failed") {
          // show dialog confirm
          return false;
        } */

        const basketIndex = [...this.baskets].findIndex(
          (basket) => basket.code == this.activeBasket
        );
        const baskets = [...this.baskets];
        baskets[basketIndex].items.push(data);
        this.baskets = [...baskets];
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
